export default {
    namespaced: true,

    state: () => {
        return {
            loadingBoxSizes: false,
            box_sizes: [],
        }
    },

    getters: {
        loadingBoxSizes(state) {
            return state.loadingBoxSizes;
        },

        box_sizes(state) {
            return state.box_sizes.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loadingBoxSizes(state, isLoading) {
            state.loadingBoxSizes = isLoading;
        },

        setBoxSize(state, box_sizes) {
            state.box_sizes = box_sizes;
        },
    },

    actions: {
        async getBoxSizes(context) {
            context.commit('loadingBoxSizes', true);

            let box_sizes = await axios.get(`${this.state.host}/get-box-sizes`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.box_sizes;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setBoxSize', box_sizes);

            context.commit('loadingBoxSizes', false);
        },

        async saveBoxSize(context, box_size) {
            let isSuccess = false;

            let url = 'add-box-size';

            if(box_size.id != null) {
                url = 'update-box-size';
            }

            await axios.post(`${this.state.host}/${url}`, box_size, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.dispatch('getBoxSizes');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteBoxSize(context, id) {
            await axios.delete(`${this.state.host}/delete-box-size/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getBoxSizes');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
